import { ContactDetails } from '../contact-details/contact-details';
import { PersonalDetails } from '../personal-details/personal-details';
import { PolicyDetails } from '../policy-details/policy-details';


export const defaultComponents = [
    {
        name: 'Policy Details',
        description: 'Provide the Liberty policy number, if available',
        component: PolicyDetails,
    },
    {
        name: 'Personal Details',
        description: 'Provide all personal details',
        component: PersonalDetails,
    },
    {
        name: 'Contact Details',
        description: 'Provide all contact details ',
        component: ContactDetails,
    },
];

export const getComponents = () => {
    return defaultComponents;
};
