import { withStyles, Typography, Grid } from '@material-ui/core';
import styles from '../../../../styles/styles';
import React from 'react';
import { Condition, FixedWidthButtonGroup, OutlinedDatePicker, OutlinedSearchSelectField, OutlinedTextField, True, WhenFieldChanges } from '../../../../forms';
import { composeValidators, requiredValidator } from '../../../../forms/validations';
import { ClaimantNavigation } from '../../../common/navigation/claimant-navigation';
import { Countries, Gender, MaritalStatuses, Relationships, Titles } from '../../../../shared/lookups';
import { getDateOfBirthFromRsaId, validateRSAIdNumber } from '../../../common/identity-details';
import { FirstNameMask, LastNameMask, NumberMask } from '../../../../forms/input-masks';
import { FixedWidthFileUpload } from '../../../../forms/form-components/fixed-width-file-upload';
import classNames from 'classnames';
import { NavigationButton } from '../../../common/navigation/navigation-button';

const personalDetails = function ({
    classes,
    form,
    editable,
    index,
    stepsLength,
    handleBackStepper,
    handleNext,
    handleBack,
}) {
    const policyholder = form.getState().values.policyholder;
    const prefix = 'lifeAssured.PersonalDetails';
    return (
        <React.Fragment>
            <Grid item xs={12} lg={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} lg={12} container justify="flex-start">
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>{' '}
                <Typography className={classes.stepHeader}> Personal Details</Typography>
            </Grid>
            {policyholder && policyholder.policyHolderType === 'Individual' && (
                <Grid item xs={12}>
                    <FixedWidthButtonGroup
                        label="Is Life Assured the same as policy holder?"
                        name="lifeAssured.PersonalDetails.sameAsPolicyHolder"
                        options={['Yes', 'No']}
                        disabled={!editable}
                        validate={requiredValidator}
                    />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        is={true}
                        set={`${prefix}.lifeAssuredTitle`}
                        to={(policyholder && policyholder.individual.personalDetails &&  policyholder.individual.personalDetails.title)}
                    />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        is={true}
                        set={`${prefix}.lifeAssuredFirstName`}
                        to={policyholder && policyholder.individual.personalDetails &&  policyholder.individual.personalDetails.firstName}
                    />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        is={true}
                        set={`${prefix}.lifeAssuredSurname`}
                        to={policyholder && policyholder.individual.personalDetails &&  policyholder.individual.personalDetails.surname}
                    />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        is={true}
                        set={`${prefix}.lifeAssuredMaidenName`}
                        to={policyholder && policyholder.individual.personalDetails &&  policyholder.individual.personalDetails.maidenName}
                    />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        is={true}
                        set={`${prefix}.lifeAssuredGender`}
                        to={policyholder && policyholder.individual.personalDetails &&  policyholder.individual.personalDetails.gender}
                    />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        is={true}
                        set={`${prefix}.lifeAssuredMaritalStatus`}
                        to={policyholder && policyholder.individual.personalDetails &&  policyholder.individual.personalDetails.maritalStatus}
                    />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        is={true}
                        set={`${prefix}.lifeAssuredDateOfMarriage`}
                        to={policyholder && policyholder.individual.personalDetails &&  policyholder.individual.personalDetails.dateOfMarriage}
                    />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        is={true}
                        set={`${prefix}.lifeAssuredDateOfDivorce`}
                        to={policyholder && policyholder.individual.personalDetails &&  policyholder.individual.personalDetails.dateOfDivorce}
                    />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        is={true}
                        set={`${prefix}.lifeAssuredIsRSAResident`}
                        to={policyholder && policyholder.individual.personalDetails &&  policyholder.individual.personalDetails.isRSAResident}
                    />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        is={true}
                        set={`${prefix}.lifeAssuredIdNumber`}
                        to={policyholder && policyholder.individual.personalDetails &&  policyholder.individual.personalDetails.idNumber}
                    />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        is={true}
                        set={`${prefix}.lifeAssuredDateOfBirth`}
                        to={policyholder && policyholder.individual.personalDetails &&  policyholder.individual.personalDetails.dateOfBirth}
                    />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        is={true}
                        set={`${prefix}.lifeAssuredPlaceOfBirth`}
                        to={policyholder && policyholder.individual.personalDetails && policyholder.individual.personalDetails.placeOfBirth}
                    />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        is={true}
                        set={`${prefix}.lifeAssuredCountryOfBirth`}
                        to={policyholder && policyholder.individual.personalDetails &&  policyholder.individual.personalDetails.countryOfBirth}
                    />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        is={true}
                        set={`${prefix}.lifeAssuredCountryOfResidence`}
                        to={policyholder && policyholder.individual.personalDetails &&  policyholder.individual.personalDetails.countryOfResidence}
                    />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        is={true}
                        set={`${prefix}.lifeAssuredProofOfIdentification`}
                        to={policyholder && policyholder.individual.personalDetails && policyholder.individual.personalDetails.proofOfIdentification}
                    />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        is={true}
                        set={`${prefix}.lifeAssuredIncomeTaxNumber`}
                        to={policyholder && policyholder.individual.employmentDetails &&  policyholder.individual.employmentDetails.saTaxReferenceNumber}
                    />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        becomes={'No'}
                        set={`${prefix}.title`}
                        to={null}
                    />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        becomes={'No'}
                        set={`${prefix}.firstName`}
                        to={null}
                    />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        becomes={'No'}
                        set={`${prefix}.surname`}
                        to={null}
                    />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        becomes={'No'}
                        set={`${prefix}.maidenName`}
                        to={null}
                    />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        becomes={'No'}
                        set={`${prefix}.gender`}
                        to={null}
                    />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        becomes={'No'}
                        set={`${prefix}.maritalStatus`}
                        to={null}
                    />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        becomes={'No'}
                        set={`${prefix}.dateOfMarriage`}
                        to={null}
                    />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        becomes={'No'}
                        set={`${prefix}.dateOfDivorce`}
                        to={null}
                    />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        becomes={'No'}
                        set={`${prefix}.isRSAResident`}
                        to={null}
                    />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        becomes={'No'}
                        set={`${prefix}.lifeAssuredIdNumber`}
                        to={null}
                    />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        becomes={'No'}
                        set={`${prefix}.dateOfBirth`}
                        to={null}
                    />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        becomes={'No'}
                        set={`${prefix}.placeOfBirth`}
                        to={null}
                    />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        becomes={'No'}
                        set={`${prefix}.countryOfBirth`}
                        to={null}
                    />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        becomes={'No'}
                        set={`${prefix}.countryOfResidence`}
                        to={null}
                    />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        becomes={'No'}
                        set={`${prefix}.proofOfIdentification`}
                        to={null}
                    />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        becomes={'No'}
                        set={`${prefix}.incomeTaxNumber`}
                        to={null}
                    />
                    {/*Setting for address copy*/}
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        is={true}
                        set={'lifeAssured.addressDetails.AddressLineOne'}
                        to={policyholder && policyholder.individual.addressDetails && policyholder.individual.addressDetails.AddressLineOne} />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        is={true}
                        set={'lifeAssured.addressDetails.postalAddressLineOne'}
                        to={policyholder && policyholder.individual.addressDetails && policyholder.individual.addressDetails.postalAddressLineOne} />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        is={true}
                        set={'lifeAssured.addressDetails.AddressLineTwo'}
                        to={policyholder && policyholder.individual.addressDetails && policyholder.individual.addressDetails.AddressLineTwo} />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        is={true}
                        set={'lifeAssured.addressDetails.postalAddressLineTwo'}
                        to={policyholder && policyholder.individual.addressDetails && policyholder.individual.addressDetails.AddressLineTwo} />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        is={true}
                        set={'lifeAssured.addressDetails.postalAddressLineThree'}
                        to={policyholder && policyholder.individual.addressDetails && policyholder.individual.addressDetails.postalAddressLineThree} />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        is={true}
                        set={'lifeAssured.addressDetails.AddressSuburb'}
                        to={policyholder && policyholder.individual.addressDetails && policyholder.individual.addressDetails.AddressSuburb} />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        is={true}
                        set={'lifeAssured.addressDetails.postalAddressSuburb'}
                        to={policyholder && policyholder.individual.addressDetails && policyholder.individual.addressDetails.postalAddressSuburb} />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        is={true}
                        set={'lifeAssured.addressDetails.AddressTown'}
                        to={policyholder && policyholder.individual.addressDetails && policyholder.individual.addressDetails.AddressTown} />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        is={true}
                        set={'lifeAssured.addressDetails.postalAddressTown'}
                        to={policyholder && policyholder.individual.addressDetails && policyholder.individual.addressDetails.postalAddressTown} />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        is={true}
                        set={'lifeAssured.addressDetails.PostalCode'}
                        to={policyholder && policyholder.individual.addressDetails && policyholder.individual.addressDetails.PostalCode} />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        is={true}
                        set={'lifeAssured.addressDetails.postalPostalCode'}
                        to={policyholder && policyholder.individual.addressDetails && policyholder.individual.addressDetails.postalPostalCode} />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        is={true}
                        set={'lifeAssured.addressDetails.Province'}
                        to={policyholder && policyholder.individual.addressDetails && policyholder.individual.addressDetails.Province} />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        is={true}
                        set={'lifeAssured.addressDetails.postalProvince'}
                        to={policyholder && policyholder.individual.addressDetails && policyholder.individual.addressDetails.postalProvince} />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        is={true}
                        set={'lifeAssured.addressDetails.sameAsResidential'}
                        to={policyholder && policyholder.individual.addressDetails && policyholder.individual.addressDetails.sameAsResidential} />

                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        becomes={'No'}
                        set={'lifeAssured.addressDetails.AddressLineOne'}
                        to={null} />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        becomes={'No'}
                        set={'lifeAssured.addressDetails.AddressLineTwo'}
                        to={null} />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        becomes={'No'}
                        set={'lifeAssured.addressDetails.AddressLineThree'}
                        to={null} />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        becomes={'No'}
                        set={'lifeAssured.addressDetails.AddressSuburb'}
                        to={null} />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        becomes={'No'}
                        set={'lifeAssured.addressDetails.AddressTown'}
                        to={null} />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        becomes={'No'}
                        set={'lifeAssured.addressDetails.PostalCode'}
                        to={null} />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        becomes={'No'}
                        set={'lifeAssured.addressDetails.Province'}
                        to={null} />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        becomes={'No'}
                        set={'lifeAssured.addressDetails.sameAsResidential'}
                        to={null} />
                    {/*End of address copy*/}

                    {/*Setting for contact details copy*/}
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        is={true}
                        set={'lifeAssured.contactDetails.homeTelephoneNumber'}
                        to={policyholder && policyholder.individual.contactDetails && policyholder.individual.contactDetails.homeTelephoneNumber} />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        is={true}
                        set={'lifeAssured.contactDetails.workTelephoneNumber'}
                        to={policyholder && policyholder.individual.contactDetails && policyholder.individual.contactDetails.workTelephoneNumber} />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        is={true}
                        set={'lifeAssured.contactDetails.cellphoneNumber'}
                        to={policyholder && policyholder.individual.contactDetails && policyholder.individual.contactDetails.cellphoneNumber} />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        is={true}
                        set={'lifeAssured.contactDetails.faxNumber'}
                        to={policyholder && policyholder.individual.contactDetails && policyholder.individual.contactDetails.faxNumber} />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        is={true}
                        set={'lifeAssured.contactDetails.emailAddress'}
                        to={policyholder && policyholder.individual.contactDetails && policyholder.individual.contactDetails.emailAddress} />

                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        becomes={'No'}
                        set={'lifeAssured.contactDetails.homeTelephoneNumber'}
                        to={null} />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        becomes={'No'}
                        set={'lifeAssured.contactDetails.workTelephoneNumber'}
                        to={null} />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        becomes={'No'}
                        set={'lifeAssured.contactDetails.cellphoneNumber'}
                        to={null} />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        becomes={'No'}
                        set={'lifeAssured.contactDetails.faxNumber'}
                        to={null} />
                    <WhenFieldChanges
                        field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                        becomes={'No'}
                        set={'lifeAssured.contactDetails.emailAddress'}
                        to={null} />
                    {/*Setting for address copy*/}

                    {policyholder && policyholder.individual.personalDetails && (
                        <WhenFieldChanges
                            field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                            is={false}
                            set={`${prefix}.lifeAssuredPassportNumber`}
                            to={policyholder.individual.personalDetails.passportNumber}
                        />
                    )}

                    {policyholder && policyholder.individual.personalDetails && (
                        <WhenFieldChanges
                            field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                            is={false}
                            set={`${prefix}.lifeAssuredCountryOfIssue`}
                            to={policyholder && policyholder.individual.personalDetails &&  policyholder.individual.personalDetails.countryOfIssue}
                        />
                    )}
                    {policyholder && policyholder.individual.personalDetails && (
                        <WhenFieldChanges
                            field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                            is={false}
                            set={`${prefix}.lifeAssuredCountryOfIssue`}
                            to={policyholder && policyholder.individual.personalDetails &&  policyholder.individual.personalDetails.countryOfIssue}
                        />
                    )}

                    {policyholder && policyholder.individual.personalDetails && (
                        <WhenFieldChanges
                            field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                            is={false}
                            set={`${prefix}.lifeAssuredDateOfIssue`}
                            to={policyholder && policyholder.individual.personalDetails &&  policyholder.individual.personalDetails.dateOfIssue}
                        />
                    )}
                    {policyholder && policyholder.individual.personalDetails && (
                        <WhenFieldChanges
                            field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                            is={false}
                            set={`${prefix}.lifeAssuredDateOfExpiry`}
                            to={policyholder && policyholder.individual.personalDetails &&  policyholder.individual.personalDetails.dateOfExpiry}
                        />
                    )}
                </Grid>
            )}

            {policyholder && policyholder.policyHolderType === 'Individual' && (
                <><Condition when={'lifeAssured.PersonalDetails.sameAsPolicyHolder'} is="Yes">
                    <True>
                        <Grid item xs={12} lg={12}>
                            <OutlinedSearchSelectField
                                name={`${prefix}.lifeAssuredTitle`}
                                options={Titles}
                                label="Title"
                                disabled={true}
                                validate={requiredValidator}
                                labelprefixed="true" />
                            <OutlinedTextField
                                name={`${prefix}.lifeAssuredFirstName`}
                                label="First Name"
                                mask={FirstNameMask}
                                validate={requiredValidator}
                                labelprefixed="true"
                                disabled={true} />

                            <OutlinedTextField
                                name={`${prefix}.lifeAssuredSurname`}
                                label="Surname"
                                validate={requiredValidator}
                                labelprefixed="true"
                                mask={LastNameMask}
                                disabled={true} />
                            <OutlinedTextField
                                name={`${prefix}.lifeAssuredMaiden`}
                                label="Maiden"
                                mask={LastNameMask}
                                disabled={true} />
                            <OutlinedSearchSelectField
                                name={`${prefix}.lifeAssuredGender`}
                                options={Gender}
                                disabled={true}
                                validate={requiredValidator}
                                labelprefixed="true" />
                            { policyholder && policyholder.individual.employmentDetails && policyholder.individual.employmentDetails.hasTaxNumber === 'Yes' &&
                                <OutlinedTextField
                                    name={`${prefix}.lifeAssuredIncomeTaxNumber`}
                                    label="SA Income tax number"
                                    validate={requiredValidator}
                                    labelprefixed="true"
                                    mask={NumberMask}
                                    disabled={true} />
                            }

                            <OutlinedSearchSelectField
                                name={`${prefix}.lifeAssuredMaritalStatus`}
                                options={MaritalStatuses}
                                disabled={true}
                                label="Marital status"
                                validate={requiredValidator}
                                labelprefixed="true" />
                            <Condition when={`${prefix}.lifeAssuredMaritalStatus`} is="Married">
                                <True>
                                    <OutlinedDatePicker
                                        name={`${prefix}.lifeAssuredDateOfMarriage`}
                                        label="Date of marriage"
                                        disableFuture
                                        validate={requiredValidator}
                                        disabled={true}
                                        labelprefixed="true" />
                                </True>
                            </Condition>
                            <Condition when={`${prefix}.lifeAssuredMaritalStatus`} is="Divorced">
                                <True>
                                    <OutlinedDatePicker
                                        name={`${prefix}.lifeAssuredDateOfDivorce`}
                                        label="Date of Divorce"
                                        disableFuture
                                        validate={requiredValidator}
                                        disabled={true}
                                        labelprefixed="true" />
                                </True>
                            </Condition>
                            <OutlinedSearchSelectField
                                name={`${prefix}.lifeAssuredIsRSAResident`}
                                options={[
                                    {
                                        value: 'Yes',
                                        label: 'Yes',
                                    },
                                    {
                                        value: 'No',
                                        label: 'No',
                                    },
                                ]}
                                disabled={true}
                                label="Are you a South African resident?"
                                validate={requiredValidator}
                                labelprefixed="true" />

                            <Condition when={`${prefix}.lifeAssuredIsRSAResident`} is="Yes">
                                <True>
                                    <OutlinedTextField
                                        name={`${prefix}.lifeAssuredIdNumber`}
                                        label="RSA ID Number"
                                        mask={NumberMask}
                                        disabled={true}
                                        labelprefixed="true" />
                                    <WhenFieldChanges
                                        field={`${prefix}.lifeAssuredIdNumber`}
                                        set={'lifeAssuredDateOfBirth'}
                                        to={getDateOfBirthFromRsaId} />
                                    <WhenFieldChanges
                                        field={`${prefix}.lifeAssuredIdNumber`}
                                        set={`${prefix}.lifeAssuredDateOfBirth`}
                                        to={getDateOfBirthFromRsaId} />
                                    <OutlinedDatePicker
                                        name={`${prefix}.lifeAssuredDateOfBirth`}
                                        validate={requiredValidator}
                                        labelprefixed="true"
                                        disabled={true}
                                        disableFuture />
                                    <OutlinedTextField
                                        name={`${prefix}.lifeAssuredPlaceOfBirth`}
                                        label="Place of Birth"
                                        validate={requiredValidator}
                                        labelprefixed="true"
                                        disabled={true} />
                                    <OutlinedSearchSelectField
                                        name={`${prefix}.lifeAssuredCountryOfBirth`}
                                        options={Countries}
                                        disabled={true}
                                        label="Country of Birth"
                                        validate={requiredValidator}
                                        labelprefixed="true" />
                                    <OutlinedSearchSelectField
                                        name={`${prefix}.lifeAssuredCountryOfResidence`}
                                        options={Countries}
                                        disabled={true}
                                        label="Country of Residence"
                                        validate={requiredValidator}
                                        labelprefixed="true" />
                                    <FixedWidthFileUpload
                                        name={`${prefix}.lifeAssuredProofOfIdentification`}
                                        label="* Upload proof of Identification"
                                        validate={requiredValidator}
                                        disabled={true}
                                        readonly={true} />
                                </True>
                            </Condition>
                            <Condition when={`${prefix}.lifeAssuredIsRSAResident`} is="No">
                                <True>
                                    <OutlinedTextField
                                        name={`${prefix}.lifeAssuredPassportNumber`}
                                        label="Passport Number"
                                        validate={requiredValidator}
                                        disabled={true}
                                        labelprefixed="true" />
                                    <OutlinedSearchSelectField
                                        name={`${prefix}.lifeAssuredCountryOfIssue`}
                                        options={Countries}
                                        disabled={true}
                                        label="Country of Issue"
                                        validate={requiredValidator}
                                        labelprefixed="true" />
                                    <OutlinedDatePicker
                                        name={`${prefix}.lifeAssuredDateOfIssue`}
                                        validate={requiredValidator}
                                        labelprefixed="true"
                                        disabled={true}
                                        disableFuture />
                                    <OutlinedDatePicker
                                        name={`${prefix}.lifeAssuredDateOfExpiry`}
                                        validate={requiredValidator}
                                        labelprefixed="true"
                                        disabled={true} />
                                    <OutlinedTextField
                                        name={`${prefix}.lifeAssuredPlaceOfBirth`}
                                        label="Place of Birth"
                                        validate={requiredValidator}
                                        labelprefixed="true"
                                        disabled={true} />
                                    <OutlinedSearchSelectField
                                        name={`${prefix}.lifeAssuredCountryOfBirth`}
                                        options={Countries}
                                        disabled={true}
                                        label="Country of Birth"
                                        validate={requiredValidator}
                                        labelprefixed="true" />
                                    <OutlinedSearchSelectField
                                        name={`${prefix}.lifeAssuredCountryOfResidence`}
                                        options={Countries}
                                        disabled={true}
                                        label="Country of Residence"
                                        validate={requiredValidator}
                                        labelprefixed="true" />
                                    <FixedWidthFileUpload
                                        name={`${prefix}.lifeAssuredProofOfIdentification`}
                                        label="* Passport"
                                        validate={requiredValidator}
                                        disabled={true}
                                        readonly={true} />

                                </True>
                            </Condition>
                        </Grid>
                    </True>
                </Condition><Condition when={'lifeAssured.PersonalDetails.sameAsPolicyHolder'} is='No'>
                    <True>
                        <WhenFieldChanges
                            field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                            is={true}
                            set={`${prefix}.firstName`}
                            to={null} />
                        <WhenFieldChanges
                            field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                            is={true}
                            set={`${prefix}.surname`}
                            to={null} />
                        <WhenFieldChanges
                            field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                            is={true}
                            set={`${prefix}.maidenName`}
                            to={null} />
                        <WhenFieldChanges
                            field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                            is={true}
                            set={`${prefix}.gender`}
                            to={null} />
                        <WhenFieldChanges
                            field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                            is={true}
                            set={`${prefix}.maritalStatus`}
                            to={null} />
                        <WhenFieldChanges
                            field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                            is={true}
                            set={`${prefix}.isRSAResident`}
                            to={null} />
                        <WhenFieldChanges
                            field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                            is={true}
                            set={`${prefix}.isRSAResident`}
                            to={null} />
                        <WhenFieldChanges
                            field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                            is={true}
                            set={`${prefix}.lifeAssuredIdNumber`}
                            to={null} />
                        <WhenFieldChanges
                            field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                            is={true}
                            set={`${prefix}.dateOfBirth`}
                            to={null} />
                        <WhenFieldChanges
                            field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                            is={true}
                            set={`${prefix}.placeOfBirth`}
                            to={null} />
                        <WhenFieldChanges
                            field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                            is={true}
                            set={`${prefix}.countryOfBirth`}
                            to={null} />
                        <WhenFieldChanges
                            field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                            is={true}
                            set={`${prefix}.countryOfResidence`}
                            to={null} />
                        <WhenFieldChanges
                            field={'lifeAssured.PersonalDetails.sameAsPolicyHolder'}
                            is={true}
                            set={`${prefix}.proofOfIdentification`}
                            to={null} />
                        <Grid item xs={12} lg={12}>
                            <OutlinedSearchSelectField
                                name={`${prefix}.relationshipToPolicyHolder`}
                                options={Relationships}
                                label="Relationship to Policy holder"
                                validate={requiredValidator}
                                labelprefixed="true" />
                            <OutlinedSearchSelectField
                                name={`${prefix}.title`}
                                options={Titles}
                                label="Title"
                                disabled={!editable}
                                validate={requiredValidator}
                                labelprefixed="true" />
                            <OutlinedTextField
                                name={`${prefix}.firstName`}
                                label="First Name"
                                mask={FirstNameMask}
                                validate={requiredValidator}
                                labelprefixed="true"
                                disabled={!editable} />

                            <OutlinedTextField
                                name={`${prefix}.surname`}
                                label="Surname"
                                validate={requiredValidator}
                                labelprefixed="true"
                                mask={LastNameMask}
                                disabled={!editable} />
                            <OutlinedTextField
                                name={`${prefix}.maiden`}
                                label="Maiden"
                                mask={LastNameMask} />
                            <OutlinedSearchSelectField
                                name={`${prefix}.gender`}
                                options={Gender}
                                disabled={!editable}
                                validate={requiredValidator}
                                labelprefixed="true" />
                            <OutlinedSearchSelectField
                                name={`${prefix}.maritalStatus`}
                                options={MaritalStatuses}
                                disabled={!editable}
                                label="Marital status"
                                validate={requiredValidator}
                                labelprefixed="true" />
                            <OutlinedTextField
                                name={`${prefix}.incomeTaxNumber`}
                                label="SA Income tax number"
                                validate={requiredValidator}
                                labelprefixed="true"
                                mask={NumberMask}
                                disabled={!editable} />
                            <Condition when={`${prefix}.maritalStatus`} is="Married">
                                <True>
                                    <OutlinedDatePicker
                                        name={`${prefix}.dateOfMarriage`}
                                        label="Date of marriage"
                                        disableFuture
                                        validate={requiredValidator}
                                        disabled={!editable}
                                        labelprefixed="true" />
                                </True>
                            </Condition>
                            <Condition when={`${prefix}.maritalStatus`} is="Divorced">
                                <True>
                                    <OutlinedDatePicker
                                        name={`${prefix}.dateOfDivorce`}
                                        label="Date of Divorce"
                                        disableFuture
                                        validate={requiredValidator}
                                        disabled={!editable}
                                        labelprefixed="true" />
                                </True>
                            </Condition>
                            <OutlinedSearchSelectField
                                name={`${prefix}.isRSAResident`}
                                options={[
                                    {
                                        value: 'Yes',
                                        label: 'Yes',
                                    },
                                    {
                                        value: 'No',
                                        label: 'No',
                                    },
                                ]}
                                disabled={!editable}
                                label="Are you a South African resident?"
                                validate={requiredValidator}
                                labelprefixed="true" />
                            <WhenFieldChanges
                                field={`${prefix}.isRSAResident`}
                                set={`${prefix}.countryOfResidence`}
                                to={null} />
                            <WhenFieldChanges
                                field={`${prefix}.isRSAResident`}
                                set={`${prefix}.proofOfIdentification`}
                                to={null}
                            />
                            <WhenFieldChanges
                                field={`${prefix}.isRSAResident`}
                                set={`${prefix}.placeOfBirth`}
                                to={null}
                            />
                            <WhenFieldChanges
                                field={`${prefix}.isRSAResident`}
                                set={`${prefix}.lifeAssuredProofOfIdentification`}
                                to={null}
                            />
                            <Condition when={`${prefix}.isRSAResident`} is="Yes">
                                <True>
                                    <OutlinedTextField
                                        name={`${prefix}.lifeAssuredIdNumber`}
                                        label="RSA ID Number"
                                        validate={composeValidators(requiredValidator, validateRSAIdNumber)}
                                        mask={NumberMask}
                                        disabled={!editable}
                                        labelprefixed="true" />
                                    <WhenFieldChanges
                                        field={`${prefix}.lifeAssuredIdNumber`}
                                        set={'dateOfBirth'}
                                        to={getDateOfBirthFromRsaId} />
                                    <WhenFieldChanges
                                        field={`${prefix}.lifeAssuredIdNumber`}
                                        set={`${prefix}.dateOfBirth`}
                                        to={getDateOfBirthFromRsaId} />
                                    <OutlinedDatePicker
                                        name={`${prefix}.dateOfBirth`}
                                        validate={requiredValidator}
                                        labelprefixed="true"
                                        disabled={!editable}
                                        disableFuture />
                                    <OutlinedTextField
                                        name={`${prefix}.placeOfBirth`}
                                        label="Place of Birth"
                                        validate={requiredValidator}
                                        labelprefixed="true"
                                        disabled={!editable} />
                                    <OutlinedSearchSelectField
                                        name={`${prefix}.countryOfBirth`}
                                        options={Countries}
                                        disabled={!editable}
                                        label="Country of Birth"
                                        validate={requiredValidator}
                                        labelprefixed="true" />
                                    <OutlinedSearchSelectField
                                        name={`${prefix}.countryOfResidence`}
                                        options={Countries}
                                        disabled={!editable}
                                        label="Country of Residence"
                                        validate={requiredValidator}
                                        labelprefixed="true" />
                                    <FixedWidthFileUpload
                                        name={`${prefix}.proofOfIdentification`}
                                        label="* Upload proof of Identification"
                                        validate={requiredValidator}
                                        readonly={!editable} />
                                </True>
                            </Condition>
                            <Condition when={`${prefix}.isRSAResident`} is="No">
                                <True>
                                    <OutlinedTextField
                                        name={`${prefix}.passportNumber`}
                                        label="Passport Number"
                                        validate={requiredValidator}
                                        disabled={!editable}
                                        labelprefixed="true" />
                                    <OutlinedSearchSelectField
                                        name={`${prefix}.countryOfIssue`}
                                        options={Countries}
                                        disabled={!editable}
                                        label="Country of Issue"
                                        validate={requiredValidator}
                                        labelprefixed="true" />
                                    <OutlinedDatePicker
                                        name={`${prefix}.dateOfIssue`}
                                        validate={requiredValidator}
                                        labelprefixed="true"
                                        disabled={!editable}
                                        disableFuture />
                                    <OutlinedDatePicker
                                        name={`${prefix}.dateOfExpiry`}
                                        validate={requiredValidator}
                                        labelprefixed="true"
                                        disabled={!editable}
                                        disableFuture />
                                    <OutlinedTextField
                                        name={`${prefix}.placeOfBirth`}
                                        label="Place of Birth"
                                        validate={requiredValidator}
                                        labelprefixed="true"
                                        disabled={!editable} />
                                    <OutlinedSearchSelectField
                                        name={`${prefix}.countryOfBirth`}
                                        options={Countries}
                                        disabled={!editable}
                                        label="Country of Birth"
                                        validate={requiredValidator}
                                        labelprefixed="true" />
                                    <OutlinedSearchSelectField
                                        name={`${prefix}.countryOfResidence`}
                                        options={Countries}
                                        disabled={!editable}
                                        label="Country of Residence"
                                        validate={requiredValidator}
                                        labelprefixed="true" />
                                    <FixedWidthFileUpload
                                        name={`${prefix}.proofOfIdentification`}
                                        label="* Passport"
                                        validate={requiredValidator}
                                        readonly={!editable} />
                                </True>
                            </Condition>
                        </Grid>
                    </True>
                </Condition></>
            )}
            {policyholder && policyholder.policyHolderType === 'Company' && (
                <Grid item xs={12} lg={12}>
                    <OutlinedSearchSelectField
                        name={`${prefix}.lifeAssuredRelationshipToPolicyHolder`}
                        options={Relationships}
                        label="Relationship to Policy holder"
                        validate={requiredValidator}
                        labelprefixed="true" />
                    <WhenFieldChanges
                        field={`${prefix}.lifeAssuredRelationshipToPolicyHolder`}
                        set={`${prefix}.otherRelationship`}
                        to={''}
                    />
                    <Condition when={`${prefix}.lifeAssuredRelationshipToPolicyHolder`} is="Other">
                        <True>
                            <OutlinedTextField
                                name={`${prefix}.otherRelationship`}
                                label="Other Relationship"
                                disabled={!editable}
                                validate={requiredValidator}
                                labelprefixed="true" />
                        </True>
                    </Condition>
                    <OutlinedSearchSelectField
                        name={`${prefix}.title`}
                        options={Titles}
                        label="Title"
                        disabled={!editable}
                        validate={requiredValidator}
                        labelprefixed="true" />
                    <OutlinedTextField
                        name={`${prefix}.firstName`}
                        label="First Name"
                        mask={FirstNameMask}
                        validate={requiredValidator}
                        labelprefixed="true"
                        disabled={!editable} />

                    <OutlinedTextField
                        name={`${prefix}.surname`}
                        label="Surname"
                        validate={requiredValidator}
                        labelprefixed="true"
                        mask={LastNameMask}
                        disabled={!editable} />
                    <OutlinedTextField
                        name={`${prefix}.maiden`}
                        label="Maiden"
                        mask={LastNameMask} />
                    <OutlinedSearchSelectField
                        name={`${prefix}.gender`}
                        options={Gender}
                        disabled={!editable}
                        validate={requiredValidator}
                        labelprefixed="true" />
                    <OutlinedSearchSelectField
                        name={`${prefix}.maritalStatus`}
                        options={MaritalStatuses}
                        disabled={!editable}
                        label="Marital status"
                        validate={requiredValidator}
                        labelprefixed="true" />
                    <Condition when={`${prefix}.maritalStatus`} is="Married">
                        <True>
                            <OutlinedDatePicker
                                name={`${prefix}.dateOfMarriage`}
                                label="Date of marriage"
                                disableFuture
                                validate={requiredValidator}
                                disabled={!editable}
                                labelprefixed="true" />
                        </True>
                    </Condition>
                    <Condition when={`${prefix}.maritalStatus`} is="Divorced">
                        <True>
                            <OutlinedDatePicker
                                name={`${prefix}.dateOfDivorce`}
                                label="Date of Divorce"
                                disableFuture
                                validate={requiredValidator}
                                disabled={!editable}
                                labelprefixed="true" />
                        </True>
                    </Condition>
                    <OutlinedSearchSelectField
                        name={`${prefix}.isRSAResident`}
                        options={[
                            {
                                value: 'Yes',
                                label: 'Yes',
                            },
                            {
                                value: 'No',
                                label: 'No',
                            },
                        ]}
                        disabled={!editable}
                        label="Are you a South African resident?"
                        validate={requiredValidator}
                        labelprefixed="true" />
                    <WhenFieldChanges
                        field={`${prefix}.isRSAResident`}
                        set={`${prefix}.countryOfResidence`}
                        to={null} />
                    <Condition when={`${prefix}.isRSAResident`} is="No">
                        <True>
                            <OutlinedSearchSelectField
                                name={`${prefix}.countryOfResidence`}
                                options={Countries}
                                disabled={!editable} />
                        </True>
                    </Condition>
                    <Condition when={`${prefix}.isRSAResident`} is="Yes">
                        <True>
                            <OutlinedTextField
                                name={`${prefix}.lifeAssuredIdNumber`}
                                label="RSA ID Number"
                                validate={composeValidators(requiredValidator, validateRSAIdNumber)}
                                mask={NumberMask}
                                disabled={!editable}
                                labelprefixed="true" />
                            <WhenFieldChanges
                                field={`${prefix}.lifeAssuredIdNumber`}
                                set={'dateOfBirth'}
                                to={getDateOfBirthFromRsaId} />
                            <WhenFieldChanges
                                field={`${prefix}.lifeAssuredIdNumber`}
                                set={`${prefix}.dateOfBirth`}
                                to={getDateOfBirthFromRsaId} />
                            <OutlinedDatePicker
                                name={`${prefix}.dateOfBirth`}
                                validate={requiredValidator}
                                labelprefixed="true"
                                disabled={!editable}
                                disableFuture />
                            <OutlinedTextField
                                name={`${prefix}.placeOfBirth`}
                                label="Place of Birth"
                                validate={requiredValidator}
                                labelprefixed="true"
                                disabled={!editable} />
                            <OutlinedSearchSelectField
                                name={`${prefix}.countryOfBirth`}
                                options={Countries}
                                disabled={!editable}
                                label="Country of Birth"
                                validate={requiredValidator}
                                labelprefixed="true" />
                            <OutlinedSearchSelectField
                                name={`${prefix}.countryOfResidence`}
                                options={Countries}
                                disabled={!editable}
                                label="Country of Residence"
                                validate={requiredValidator}
                                labelprefixed="true" />
                            <FixedWidthFileUpload
                                name={`${prefix}.proofOfIdentification`}
                                label="* Upload proof of Identification"
                                validate={requiredValidator}
                                readonly={!editable} />
                        </True>
                    </Condition>
                    <Condition when={`${prefix}.isRSAResident`} is="No">
                        <True>
                            <OutlinedTextField
                                name={`${prefix}.passportNumber`}
                                label="Passport Number"
                                validate={requiredValidator}
                                disabled={!editable}
                                labelprefixed="true" />
                            <OutlinedSearchSelectField
                                name={`${prefix}.countryOfIssue`}
                                options={Countries}
                                disabled={!editable}
                                label="Country of Issue"
                                validate={requiredValidator}
                                labelprefixed="true" />
                            <OutlinedDatePicker
                                name={`${prefix}.dateOfIssue`}
                                validate={requiredValidator}
                                labelprefixed="true"
                                disabled={!editable}
                                disableFuture />
                            <OutlinedDatePicker
                                name={`${prefix}.dateOfExpiry`}
                                validate={requiredValidator}
                                labelprefixed="true"
                                disabled={!editable}
                                disableFuture />
                            <OutlinedTextField
                                name={`${prefix}.placeOfBirth`}
                                label="Place of Birth"
                                validate={requiredValidator}
                                labelprefixed="true"
                                disabled={!editable} />
                            <OutlinedSearchSelectField
                                name={`${prefix}.countryOfBirth`}
                                options={Countries}
                                disabled={!editable}
                                label="Country of Birth"
                                validate={requiredValidator}
                                labelprefixed="true" />
                            <OutlinedSearchSelectField
                                name={`${prefix}.countryOfResidence`}
                                options={Countries}
                                disabled={!editable}
                                label="Country of Residence"
                                validate={requiredValidator}
                                labelprefixed="true" />
                            <FixedWidthFileUpload
                                name={`${prefix}.proofOfIdentification`}
                                label="* Passport"
                                validate={requiredValidator}
                                readonly={!editable} />
                        </True>
                    </Condition>
                </Grid>
            )}

            <ClaimantNavigation
                form={form}
                handleBackStepper={handleBackStepper}
                handleNext={handleNext}
                prefix={'lifeassured-personal'}
                index={index}
            />
            <Grid item xs={12} className={classes.navigationGroup}>
                <NavigationButton
                    className={classNames(classes.buttonSecondary, classes.fullWidthButton)}
                    onClick={() => handleBack(form)}
                    id={`${prefix}-previous-button`}
                    label="Previous Section"
                />
            </Grid>
        </React.Fragment>
    );
};


export const PersonalDetails = withStyles(styles)(personalDetails);

