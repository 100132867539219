import * as React from 'react';
import { FormHelperText, Typography, withStyles } from '@material-ui/core';
import { getComponentId, nameToLabel, processMetaForErrors } from '../../form-component-utilities';
import styles from '../styles';
import { ToggleButton, ToggleButtonGroup as MuiToggleButtonGroup } from '@material-ui/lab';
import { getValidatedLabel } from '../../form-component-utilities/label-validator/label-validator';

const toggleButtonGroup = ({
    classes,
    label,
    helperText,
    hideLabel,
    onChange,
    id,
    options,
    input,
    meta,
    disabled,
    ...rest
}) => {
    const { name, value } = input;
    const _id = getComponentId({ id, name });
    const { errorMessage, showError } = processMetaForErrors(meta);
    const style = showError ? { color: '#cd003d' } : undefined;
    const toggleButton = showError ? classes.toggleButtonError : classes.toggleButton;
    const handleToggle = (event, newValue) => {
        if (newValue !== null) {
            input.onChange(newValue);
            if (onChange) {
                onChange(event, newValue);
            }
        }
    };

    return (
        <div className={classes.textField}>
            {!hideLabel && (
                <Typography className={classes.cssLabel} style={style}>
                    {!label
                        ? getValidatedLabel(rest, nameToLabel({ label, name }))
                        : getValidatedLabel(rest, label)}
                </Typography>
            )}
            <MuiToggleButtonGroup
                {...rest}
                id={_id}
                value={value}
                exclusive
                onChange={handleToggle}
                className={disabled ? classes.toggleButtonDisabled : classes.toggleButtonGroup}
                aria-label={name}
                style={style}
            >
                {options.map((option, index) => {
                    return (
                        <ToggleButton
                            key={`${name}-${index}`}
                            id={`${name}${index}`}
                            name={name}
                            classes={{
                                label:
                                    value === option
                                        ? classes.overWriteSelectedLabel
                                        : classes.overWriteLabel,
                                selected: classes.overWriteSelected,
                            }}
                            className={toggleButton}
                            value={option}
                            disabled={disabled}
                            children={option}
                        />
                    );
                })}
            </MuiToggleButtonGroup>
            {(showError || !!helperText) && (
                <FormHelperText error={showError} component="pre">
                    {showError ? errorMessage : helperText}
                </FormHelperText>
            )}
        </div>
    );
};

export const ToggleButtonGroup = withStyles(styles)(toggleButtonGroup);
