import * as React from 'react';

import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import { AppBar, Button, CssBaseline, Toolbar, Typography, Grid } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import ReduxToastr from 'react-redux-toastr';
import ConfirmationDialog from '../forms/confirmation-dialog';
import { ModalView } from '../forms/form-modal';
import { LoadingScreenView } from '../forms/loading-screen';
import logo from './images/liberty-logo-white.svg';

function styles(theme) {
    return createStyles({
        root: {
            flexGrow: 1,
        },
        appFrame: {
            zIndex: 1,
            display: 'flex',
            width: '100%',
            minHeight: '100vh',
            backgroundColor: '#F0F1F4',
        },
        appBar: {
            position: 'fixed',
            boxShadow: 'none',
        },
        appFooter: {
            color: '#FFFFFF',
            backgroundColor: theme.palette.primary.main,
            height: '55px',
            position: 'fixed',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            bottom: 0,
        },
        appFooterContent: {
            margin: '10px',
            marginLeft: '40px',
            marginBottom: '0px',
        },
        appFooterAgreement: {
            height: '20px',
            width: '93px',
            color: '#FFFFFF',
            fontFamily: 'Noto Sans',
            fontSize: '12px',
            letterSpacing: '0',
            lineHeight: '20px',
            textDecoration: 'underline',
            cursor: 'pointer',
        },
        appFooterDisclaimer: {
            height: '20px',
            opacity: 0.6,
            color: '#FFFFFF',
            fontFamily: 'Noto Sans',
            fontSize: '12px',
            letterSpacing: '0',
            lineHeight: '20px',
            textAlign: 'center',
        },
        content: {
            marginTop: '64px',
            padding: theme.spacing(3),
            paddingTop: theme.spacing(0.6),
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        logo: {
            marginTop: 10,
            marginRight: 20,
        },
        logoText: {
            position: 'relative',
            bottom: '20px',
            textTransform: 'uppercase',
            fontWeight: 'bold',
        },
        logoutButton: {
            marginLeft: '40px',
            cursor: 'pointer',
            position: 'relative',
            top: 6,
            backgroundColor: `${theme.palette.primary.main}`,
            color: 'white',
            border: `1px solid white`,
            paddingLeft: '45px',
            paddingRight: '45px',
            textTransform: 'none',
            marginTop: '-15px',
        },
    });
}
function MasterLayout({
    classes,
    children,
    checkIfLoggedInRedirectIfNot,
    account,
    loadBankNamesAction,
    loadUniversalBranchesAction,
    logout,
    goToHome,
}) {
    checkIfLoggedInRedirectIfNot();
    loadBankNamesAction();
    loadUniversalBranchesAction();
    function goBackToDashboard(evt) {
        evt.preventDefault();
        goToHome();
    }
    return (
        <div className={classes.root}>
            <CssBaseline />
            <div className={classes.appFrame}>
                <AppBar className={classes.appBar}>
                    <Toolbar style={{ position: 'sticky' }}>
                        <Grid container justify="space-between" alignItems="center">
                            <Grid item>
                                <Typography variant="h6" color="inherit" noWrap>
                                    <a
                                        href="/#"
                                        style={{ textDecoration: 'none', color: 'white' }}
                                        onClick={goBackToDashboard}
                                    >
                                        <img src={logo} alt="Liberty" className={classes.logo} />
                                    </a>
                                </Typography>
                            </Grid>
                            <Grid item>
                                {account && (
                                    <Typography color="inherit">
                                        {`${account.firstName} ${account.lastName}`}
                                        <Button onClick={logout} className={classes.logoutButton}>
                                            Log out
                                        </Button>
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <div
                    className={
                        'MuiGrid-root MuiGrid-item MuiGrid-grid-xs-1 MuiGrid-grid-md-1 MuiGrid-grid-lg-2 MuiGrid-grid-xl-3'
                    }
                ></div>
                <div
                    className={
                        'MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-md-12 MuiGrid-grid-lg-12 MuiGrid-grid-xl-12'
                    }
                >
                    <main id="mainElement" className={classes.content}>
                        <LoadingScreenView />
                        {children}
                        <ModalView />
                        <ReduxToastr
                            timeOut={5000}
                            newestOnTop={true}
                            preventDuplicates
                            position="top-right"
                            transitionIn="bounceIn"
                            transitionOut="fadeOut"
                            progressBar
                        />

                        <ConfirmationDialog />
                    </main>
                </div>
                <div
                    className={
                        'MuiGrid-root MuiGrid-item MuiGrid-grid-xs-1 MuiGrid-grid-md-1 MuiGrid-grid-lg-2 MuiGrid-grid-xl-3'
                    }
                ></div>
            </div>
            <footer className={classes.appFooter}>
                <div className={classes.appFooterContent}>
                    <span
                        className={classes.appFooterAgreement}
                        onClick={() =>
                            (window.location.href =
                                'https://www.liberty.co.za/Pages/user-agreement.aspx')
                        }
                    >
                        User agreement
                    </span>
                    <br />
                    <span className={classes.appFooterDisclaimer}>
                        {`Liberty Group Limited (Reg. no 1957/002788/06) is a registered Long Term Insurer and an Authorised Financial Services Provider (FAIS no 2409).`}
                    </span>
                </div>
            </footer>
        </div>
    );
}

export default withStyles(styles, { withTheme: true })(MasterLayout);
