import { ClaimTypes } from '../../shared/lookups';
import { deathComponents } from '../death-claim/death-claim';
import { funeralComponents, validateFuneralClaim } from '../funeral/funeral-claim';
import { raDeathComponents } from '../ra-death-claim/retirement-annuity-claim';
import { lifeStyleComponents, validateLivingLifeStyleClaim } from '../living-lifestyle/living-lifestyle-claim';

export function validateClaim(form, activeStep) {
    switch (form.claimType) {
        case ClaimTypes.DeathClaim:
            return validateDeathClaim(form, activeStep);
        case ClaimTypes.RADeathClaim:
            return validateRetirementClaim(form, activeStep);
        case ClaimTypes.FuneralClaim:
            return validateFuneralClaim(form, activeStep);
        case ClaimTypes.LivingLifestyleClaim:
            return validateLivingLifeStyleClaim(form, activeStep);
        default:
            return form;
    }
}


function validateDeathClaim(form, activeStep) {
    const sessionStep = sessionStorage.getItem('activeComponentStep');
    const activeComponentStep = activeStep.name + (sessionStep ? ' - ' + sessionStep : '');

    form.activeStep = Array.isArray(form.activeStep) ? form.activeStep : [form.activeStep].filter(Boolean);

    if (!form.activeStep.includes(activeComponentStep)) {
        form.activeStep.push(activeComponentStep);
    }

    return {
        ...form,
        claimants: getUpdatedClaimants(form),
        activeStep: form.activeStep
    };
}


function validateRetirementClaim(form, activeStep) {
    const sessionStep = sessionStorage.getItem('activeComponentStep');
    const activeComponentStep = activeStep.name + (sessionStep ? ' - ' + sessionStep : '');

    form.activeStep = Array.isArray(form.activeStep) ? form.activeStep : [form.activeStep].filter(Boolean);

    if (!form.activeStep.includes(activeComponentStep)) {
        form.activeStep.push(activeComponentStep);
    }

    return {
        ...form,
        claimants: getUpdatedClaimants(form),
        activeStep: form.activeStep
    };
}

function getUpdatedClaimants(form) {
    const updatedClaimants = [];
    if (form.claimants && form.claimants.length > 0) {
        form.claimants.forEach(claimant => {
            const updatedClaimant = {
                ...claimant,
                individual: claimant.claimantType === 'Individual' ? claimant.individual : null,
                entity: claimant.claimantType === 'Entity' ? claimant.entity : null,
            };
            updatedClaimants.push(updatedClaimant);
        });
    }
    return updatedClaimants;
}

export function getComponentSteps(claim) {
    switch (claim.claimType) {
        case ClaimTypes.DeathClaim:
            return deathComponents;
        case ClaimTypes.RADeathClaim:
            return raDeathComponents;
        case ClaimTypes.FuneralClaim:
            return funeralComponents(claim);
        case ClaimTypes.LivingLifestyleClaim:
            return lifeStyleComponents;
        default:
            return undefined;
    }
}
