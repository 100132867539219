import { createStyles } from '@material-ui/core';

export default function styles(theme) {
    return createStyles({
        container: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        textField: {
            maxWidth: '368px',
            width: '100%',
            margin: '12px auto auto 1px',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
            position: 'relative',
            borderColor: `#36B37E !important`,
        },
        textFieldWhite: {
            maxWidth: '368px',
            width: '100%',
            margin: '12px auto',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
            position: 'relative',
            borderColor: `#8B92A7 !important`,
            backgroundColor: '#FCFCFC',
        },
        dropContainerPos: {
            width: '100%',
            height: '100%',
            marginTop: '-10px',
            '& div:nth-child(3) ul li span:nth-child(3)': {
                marginLeft: '10px',
                color: '#6f7b95',
            },
            maxWidth: 'none',
        },
        cssLabel: {
            color: '#0D1325',
            fontSize: '12px',
            letterSpacing: 0,
            lineHeight: '20px',
            marginBottom: 4,
        },
        cssOutlinedInputShaded: {
            '&$cssFocused $notchedOutline': {
                borderColor: `#36B37E`,
                borderWidth: '1px',
            },
            backgroundColor: '#F0F1F4',
        },
        cssOutlinedInput: {
            '&$cssFocused $notchedOutline': {
                borderColor: `#36B37E`,
                borderWidth: '1px',
            },
            backgroundColor: '#FFFFFF',
        },
        cssFocused: {},
        selectControl: {
            backgroundColor: '#FFFFFF',
            borderRadius: '4px',
            height: '56px',
        },
        selectControlShaded: {
            border: '1px solid #cccccc',
            borderRadius: '4px',
            height: '56px',
        },
        notchedOutline: {
            borderWidth: '1px',
        },
        root: {
            flexGrow: 1,
            height: 250,
        },
        input: {
            display: 'flex',
            width: '100%',
            marginTop: '-2px',
            marginLeft: '4px',
            borderColor: `#36B37E !important`,
            borderWidth: '1px',
        },
        chip: {
            margin: `${theme.spacing() / 2}px ${theme.spacing() / 4}px`,
        },
        chipFocused: {
            borderColor: `#36B37E !important`,
            borderWidth: '1px',
        },
        noOptionsMessage: {
            padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
        },
        radioGroup: {
            display: 'inline-block',
            flexDirection: 'row',
            marginTop: -4,
        },
        radioGroupSecondary: {
            flexDirection: 'column',
            marginTop: -4,
        },
        radioOption: {
            marginLeft: 14,
            marginRight: 14,
        },
        overWriteLabel: {
            width: '0%',
        },
        overWriteSelectedLabel: {
            width: '0%',
            backgroundColor: '#214EBF',
            color: '#FFFFFF',
        },
        overWriteSelected: {
            backgroundColor: '#214EBF',
            color: '#FFFFFF',
        },
        toggleButton: {
            boxSizing: 'border-box',
            height: '48px',
            width: '184px',
            borderRadius: '4px 4px 4px 4px',
            textTransform: 'none',
            border: '2px solid #214EBF',
            backgroundColor: '#FFFFFF',
            '&:hover': {
                background: '#214EBF',
                borderColor: '#214EBF',
                color: '#FFFFFF',
            },
        },
        toggleButtonError: {
            boxSizing: 'border-box',
            height: '48px',
            width: '184px',
            borderRadius: '4px 4px 4px 4px',
            textTransform: 'none',
            border: '2px solid #cd003d',
            backgroundColor: '#FFFFFF',
            '&:hover': {
                background: '#214EBF',
                borderColor: '#214EBF',
                color: '#FFFFFF',
            },
        },
        toggleButtonGroup: {
            backgroundColor: '#214EBF',
            color: '#FFFFFF',
            maxWidth: '368px',
        },
    });
}
