import React, { useState, useEffect } from 'react';

import { withStyles } from '@material-ui/core';
import { push } from 'connected-react-router';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { GenericForm } from '../../forms';
import { hideLoading, showLoading } from '../../forms/loading-screen/actions';
import axios from '../../http-request';
import { prettifiedErrors } from '../../shared/helper-functions';
import styles from '../../styles/styles';
import successIcon from '../../styles/icons/success.svg';
import { updateClaim } from '../dashboard/claim-selection-actions';
import { ErrorsPanel } from './errors-panel';
import { getComponentSteps, validateClaim } from './claim-validation';

const { env } = require('../../env/env');

function scrollToTop() {
    setTimeout(() => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, 200);
}

function ClaimStepperComponent({
    classes,
    headerComponent,
    goToFormTypeSelection,
    goToDashboard,
    claim,
    defaultInitialValues,
    isOnlyReferencedClaims,
    hideLoading,
    showLoading,
    updateClaim,
}) {
    const claimId = sessionStorage.getItem('claimId');
    if (!claimId) {
        claim = claim || defaultInitialValues;
    }
    const [referenceNo, setReferenceNo] = useState(0);
    const [submissionState, setSubmissionState] = useState(false);
    let stepIndex = +sessionStorage.getItem('activeStepIndex');
    const [activeStepIndex, setActiveStepIndex] = useState(stepIndex || 0);
    const editable =
        (!claim || !claim.status || claim.status !== 'Submitted') && !isOnlyReferencedClaims;
    const [errorList, setErrors] = useState([]);

    if (!claim) {
        if (claimId) {
            showLoading();
            getClaimById(claimId)
                .then(response => {
                    let foundClaim = response.data;
                    if (foundClaim) {
                        updateClaim(foundClaim);
                        updateStepIndexAll(stepIndex);
                    }
                })
                .finally(() => {
                    hideLoading();
                });
        } else {
            push('/dashboard');
        }
    }

    const updateStepIndexAll = (index, allowNext = false) => {
      sessionStorage.setItem('verticalStepIndex', '0');
        sessionStorage.setItem('canGoToNextPage', 'false');

        if (editable) {
            if (index > activeStepIndex && !allowNext) {
                return;
            }
        }

        sessionStorage.setItem('activeStepIndex', index.toString());
        setActiveStepIndex(index);
    };

    useEffect(() => {
        scrollToTop();
        closeErrorPanel();
    }, [activeStepIndex]);

    const components = getComponentSteps(claim || defaultInitialValues);

    function returnToDashboard() {
        setSubmissionState(false);
        resetStepperSessionKeys();
        goToDashboard();
    }

    function closeErrorPanel(event) {
        event && event.preventDefault();
        setErrors([]);
    }

    function handleBack(form) {
        return function _handleBack() {
            setErrors([]);
            resetForm(form);
            if (activeStepIndex > 0) {
                for (const key in sessionStorage) {
                    if (key.indexOf('claimantStep') > -1) {
                        sessionStorage.setItem(key, '0');
                    }
                }
                return updateStepIndexAll(activeStepIndex - 1);
            }
            return goToFormTypeSelection();
        };
    }

    function resetForm(form) {
        form.reset(claim);
    }

    function setFormErrors(form) {
        const errors = form.getState().errors;
        setErrors(prettifiedErrors(errors));
    }

    function shouldInitiateClaim() {
        return activeStepIndex === components.length - 1;
    }

    function handleSubmit(form) {
        return function () {
            if (form.hasValidationErrors || Object.keys(form.form.getState().errors).length > 0) {
                setErrors(prettifiedErrors(form.form.getState().errors));
                form.form.submit();
                scrollToTop();
            } else {
                setErrors([]);
                if (activeStepIndex !== (components.length - 1)) {
                    updateStepIndexAll(activeStepIndex + 1, true);
                }

                form.form.submit();
            }
        };
    }

    async function validateSubmit(subMittedValues) {
        const errors = {};
        if (subMittedValues.status === 'Submitted' && shouldInitiateClaim()) {
            returnToDashboard();
            return;
        }
        if (Object.keys(errors).length > 0) {
            return errors;
        }
        try {
            const numberOfParams = Object.keys(subMittedValues).length;
            if (numberOfParams === 0) {
                return goToNextPage();
            }
            showLoading();

            const validatedForm = validateClaim(subMittedValues, components[activeStepIndex]);
            /**@type {import('axios').AxiosRequestConfig} */
            const config = {
                url: subMittedValues._id
                    ? `${env.REACT_APP_API_URL}/claim-form/${subMittedValues._id}`
                    : `${env.REACT_APP_API_URL}/claim-form`,
                data: { ...validatedForm, initiateClaim: shouldInitiateClaim() },
                method: subMittedValues._id ? 'PUT' : 'POST',
            };
            const response = await axios(config);
            if (response && response.data) {
                sessionStorage.setItem('claimId', response.data._id);
                updateClaim(response.data);
            }
            const canGoToNextPage = sessionStorage.getItem('canGoToNextPage') === 'true';
            if (canGoToNextPage || (activeStepIndex <= 0 && !canGoToNextPage)) {
                sessionStorage.setItem('verticalStepIndex', '0');
                return goToNextPage(response);
            }

            return hideLoading();
        } catch (err) {
            hideLoading();
            toastr.error(
                `Error ${err.heading || 'saving'} claim`,
                err.message || 'There was an error submitting your claim to the server',
                { timeOut: 0 }
            );
        }

        return errors;

        function goToNextPage(response) {
            hideLoading();
            if (shouldInitiateClaim()) {
                if (!subMittedValues.agreedToTermsAndConditions) {
                    setErrors([
                        {
                            label: 'Validation Error',
                            value: 'Please read and accept Terms and Conditions before submitting',
                        },
                    ]);
                    return errors;
                }
                if (response && response.data && response.data.status === 'Submission Error') {
                    setErrors([
                        {
                            label: 'Submission Error',
                            value:
                                'An error has occurred, please try again later or alternatively contact us at claimsform@liberty.co.za for further assistance.',
                        },
                    ]);
                    return errors;
                }

                setReferenceNo(response.data.claimReference.referenceId);
                setSubmissionState(true);
                return errors;
            }
            if (activeStepIndex < components.length) {
                updateStepIndexAll(activeStepIndex + 1, true);
            }
        }
    }

    function DisplayClaimHeader() {
        if (headerComponent) {
            const ClaimHeader = headerComponent;
            return (
                <ClaimHeader
                    components={components}
                    stepThrough={!editable}
                    updateStepIndexAll={updateStepIndexAll}
                    classes={classes}
                    activeStepIndex={activeStepIndex}
                />
            );
        }

        return null;
    }

    function renderForm(form) {
        if (!components[activeStepIndex]) {
            return null;
        }
        const StepRenderComponent = components[activeStepIndex].component;
        return (
            <StepRenderComponent
                form={form.form}
                editable={editable}
                handleSubmit={handleSubmit(form)}
                handleBack={handleBack(form.form)}
                resetForm={resetForm}
                setFormErrors={setFormErrors}
                setErrors={setErrors}
            />
        );
    }

    return (
        <React.Fragment>
            <ErrorsPanel errors={errorList} closePanel={closeErrorPanel} />
            <DisplayClaimHeader />
            <GenericForm
                onSubmit={validateSubmit}
                initialValues={claim}
                render={renderForm}
                children={undefined}
            />
            <br />
            <br />
            <SweetAlert
                custom
                customIcon={<img src={successIcon} alt="Success Icon" />}
                confirmBtnCssClass={classes.submittedButton}
                confirmBtnText="Done"
                onConfirm={() => returnToDashboard()}
                show={submissionState}
                customClass={classes.submittedAlert}
                title=""
            >
                <ClaimSubmitted referenceNo={referenceNo} />
            </SweetAlert>
        </React.Fragment>
    );
}

const getClaimById = async id => {
    /**@type {import('axios').AxiosRequestConfig} */
    const config = {
        url: `${env.REACT_APP_API_URL}/claim-form/${id}`,
        method: 'GET',
    };
    const response = await axios(config);
    return response;
};

export function resetStepperSessionKeys() {
    sessionStorage.removeItem('claimId');
    sessionStorage.removeItem('activeStepIndex');
    sessionStorage.removeItem('verticalStepIndex');
    sessionStorage.removeItem('canGoToNextPage');
    for (const key in sessionStorage) {
        if (key.indexOf('claimantStep') > -1) {
            sessionStorage.removeItem(key);
        }
    }
}

const ClaimSubmitted = withStyles(styles)(({ classes, referenceNo }) => {
    return (
        <div className={classes.submittedContainer}>
            <div className={classes.submittedTitle}>Claim Submitted</div>
            <div className={classes.submittedReference}>Ref No - {referenceNo}</div>
            <div className={classes.submittedMessage}>
                We will keep you updated on the progress of your
                <br />
                claim and let you know if we need more information or
                <br />
                additional documents to assess and process the claim.
            </div>
        </div>
    );
});

const mapStateToProps = state => {
    const claimSelection = (state && state.claimSelection) || {};
    return {
        claim: claimSelection.claim,
        isOnlyReferencedClaims:
            claimSelection.referencedClaims && claimSelection.referencedClaims.length > 0,
        isSingleReferencedClaim:
            claimSelection.isOnlyReferencedClaims &&
            claimSelection.referencedClaims &&
            claimSelection.referencedClaims.length === 1,
    };
};

const mapDispatchToProps = {
    goToFormTypeSelection: () => push('/'),
    goToDashboard: () => push('/'),
    hideLoading,
    showLoading,
    updateClaim,
};

export const ClaimStepper = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(ClaimStepperComponent));
